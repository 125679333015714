<template>
  <div>
    <div class="jjfa">
      <div class="jjfalisttou">
        <div class="jjfalist">
          <div class="jjfalistevery ">
              <span  class="jjfalisteverytitle jjfalistevery_span">数智化转型</span>
            </div>
            <div class="jjfalistevery">
              <span class="jjfalisteverytitle">人工智能</span>
            </div>
            <div class="jjfalistevery">
              <span class="jjfalisteverytitle">大数据</span>
            </div>
            <div class="jjfalistevery">
              <span class="jjfalisteverytitle">物联网</span>
            </div>
            <div class="jjfalistevery">
              <span class="jjfalisteverytitle">数字孪生</span>
            </div>
        </div>
      </div>

      <div class="jjfacontent">




        <div class="box">
        <ul>
            <li class="current">
              <div class="jjfatitle1">
                <div class="hxystitleback"></div>
                <div class="hxystitl-ti">企业数智化转型整体解决方案</div>
                <div class="hxystitleback2"></div>
              </div>
              <div class="jjfalisteveryindurce">为企事业单位提供管理信息化及咨询、运维与实施一体化解决方案。如：财务、人事、办公、法务、审计、投资、采购、EHS、党建等管理领域，通过PC、移动App、微信小程序等多端应用，全方位支持企业日常管理需要，帮助企业提升管理水平，以管理优化促进业务发展。</div>
             <img class="homebanner1" src=".././assets/fwzc_banner1.png" alt="">
            </li>
            <li>
              <div class="jjfatitle2">
                <div class="hxystitleback"></div>
                <div class="hxystitl-ti">人工智能技术体系</div>
                <div class="hxystitleback2"></div>
              </div>
              <div class="jjfalisteveryindurce"></div>
              <img class="homebanner2" src=".././assets/fwzc_banner2.png" alt="">
            </li>
            <li>
              <div class="jjfatitle3">
                <div class="hxystitleback"></div>
                <div class="hxystitl-ti">大数据技术体系</div>
                <div class="hxystitleback2"></div>
              </div>
              <div class="jjfalisteveryindurce"></div>
              <img class="homebanner3" src=".././assets/fwzc_banner3.png" alt="">
            </li>
            <li>
              <div class="jjfatitle4">
                <div class="hxystitleback"></div>
                <div class="hxystitl-ti">物联网技术体系</div>
                <div class="hxystitleback2"></div>
              </div>
              <div class="jjfalisteveryindurce"></div>
              <img class="homebanner4" src=".././assets/fwzc_banner4.png" alt="">
            </li>
            <li>
              <div class="jjfatitle5">
                <div class="hxystitleback"></div>
                <div class="hxystitl-ti">数字孪生可视化应用</div>
                <div class="hxystitleback2"></div>
              </div>
              <div class="jjfalisteveryindurce">数字孪生是充分利用物理模型、传感器更新、运行历史等数据，集成多学科、多物理量、多尺度、多概率的仿真过程，在虚拟空间中完成映射，从而反映相对应的实体装备的全生命周期过程。数字孪生是一种超越现实的概念，可以被视为一个或多个重要的、彼此依赖的装备系统的数字映射系统。</div>
              <img class="homebanner5" src=".././assets/fwzc_banner5.png" alt="">
            </li>
        </ul>

        <div @click="toleft" class="left"><img src=".././assets/turnleft.png" alt=""></div>
        <div @click="toright" class="right"><img src=".././assets/turnright.png" alt=""></div>
    </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {

    data(){
      return{
        index:0,
        activeIndex: '1',
      }
    },
    mounted(){
      var uli = document.querySelectorAll('.box ul li')
      var oli = document.querySelectorAll('.jjfalistevery span')
       for (let i = 0; i < oli.length; i++) {
        oli[i].index = i
        oli[i].onclick = ()=>{
            for (var j = 0; j < uli.length; j++) {
                uli[j].className = ''
                oli[j].className = ''
            }
            this.index = i
            console.log(this.index)
            oli[this.index].className = 'jjfalistevery_span'
            uli[this.index].className = 'current'
        }
    }
    },
    computed:{

    },
    methods:{
      go(path){
        this.$router.push(
          {
            path:path
          }
        )
      },
      toright(){
         var uli = document.querySelectorAll('.box ul li')
         var oli = document.querySelectorAll('.jjfalistevery span')
         this.index++
         console.log(this.index,uli.length)
        if (this.index == uli.length) {
            this.index = 0
        }
        for (var i = 0; i < uli.length; i++) {
            uli[i].className = ''
            oli[i].className = ''
        }
        uli[this.index].className = 'current'
        oli[this.index].className = 'jjfalistevery_span'
      },
      toleft(){
        var uli = document.querySelectorAll('.box ul li')
        var oli = document.querySelectorAll('.jjfalistevery span')
         this.index--
        if (this.index < 0) {
            this.index = uli.length - 1
        }
        for (var i = 0; i < uli.length; i++) {
            uli[i].className = ''
            oli[i].className = ''
        }
        uli[this.index].className = 'current'
        oli[this.index].className = 'jjfalistevery_span'
      }
    },
  }
</script>


<style lang="scss" scoped>
.jjfalisttou{
  width: 100%;
  margin: 0 auto;
  height: 80px;
  background: #F8F8F8;
  line-height: 80px;
}
.active{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
  border-bottom: 1px solid #FFA000;
}
.menu{
  position: absolute;
  display: none;
  top: 45px;
}
.menu li:hover{
  color: #FFA000;
}
.indexli2{
  position: relative;
}
.indexli2:hover .menu{
  display: block;
}
// 合作伙伴
.hzhb{

  background: #F8F8F8;
  // width: 1903px;
  height: 884px;
  margin: 0 auto;
  .hzhbcontent{
    padding-top: 80px;
      width: 1200px;
      height: inherit;
      margin: 0 auto;
      .hzhbtitle{
  width: 450px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 65px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('.././assets/righticon.png') no-repeat center center;
  }
}
  }

}
//
.yyfaimglie1,.yyfaimglie2,.yyfaimglie3{
  width: 32%;
  height: 100%;

}
// 行业应用方案
.yyfaimg1:hover .yyfaimg1mask{
          display: block;
        }
.yyfaimg1{

        width: 386px;
        height: 240px;

        position: relative;
        .img{
          position: absolute;
          left: 0;
          top: 0;
        }

        .yyfaimg1title{
          color: #fff;
          background: #332716;
          opacity: 0.8;
          text-align: center;
          width: 386px;
          line-height: 60px;
          position: absolute;
          bottom: 0;
        }
        .yyfaimg1mask{
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #FFA000;
          opacity: 0.9;
        }
      }
      //
      .yyfaimg2:hover .yyfaimg2mask{
          display: block;
        }
.yyfaimg2{
        width: 386px;
        height: 280px;

        position: relative;
        .img{
          position: absolute;
          left: 0;
          top: 0;
        }

        .yyfaimg2title{
          color: #fff;
          background: #332716;
          opacity: 0.8;
          text-align: center;
          width: 386px;
          line-height: 60px;
          position: absolute;
          bottom: 0;
        }
        .yyfaimg2mask{
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #FFA000;
          opacity: 0.9;
        }
      }
      //
         .yyfaimg3:hover .yyfaimg3mask{
          display: block;
        }
.yyfaimg3{
        width: 386px;
        height: 320px;

        position: relative;
        .img{
          position: absolute;
          left: 0;
          top: 0;
        }

        .yyfaimg3title{
          color: #fff;
          background: #332716;
          opacity: 0.8;
          text-align: center;
          width: 386px;
          line-height: 60px;
          position: absolute;
          bottom: 0;
        }
        .yyfaimg3mask{
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #FFA000;
          opacity: 0.9;
        }
      }
      //
      .yyfaimg4:hover .yyfaimg4mask{
          display: block;
        }
.yyfaimg4{
  margin-top: 10px;
        width: 386px;
        height: 340px;

        position: relative;
        .img{
          position: absolute;
          left: 0;
          top: 0;
        }

        .yyfaimg4title{
          color: #fff;
          background: #332716;
          opacity: 0.8;
          text-align: center;
          width: 386px;
          line-height: 60px;
          position: absolute;
          bottom: 0;
        }
        .yyfaimg4mask{
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #FFA000;
          opacity: 0.9;
        }
      }
      //
      .yyfaimg5:hover .yyfaimg5mask{
          display: block;
        }
.yyfaimg5{
  margin-top: 10px;
        width: 386px;
        height: 240px;

        position: relative;
        .img{
          position: absolute;
          left: 0;
          top: 0;
        }

        .yyfaimg5title{
          color: #fff;
          background: #332716;
          opacity: 0.8;
          text-align: center;
          width: 386px;
          line-height: 60px;
          position: absolute;
          bottom: 0;
        }
        .yyfaimg5mask{

          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #FFA000;
          opacity: 0.9;
          .yyfaimg5masktitle{
            width: 48px;
            margin-left: 50%;
            transform: translateX(-24px);
            line-height: 50px;
            font-size: 24px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
          }
          .yyfaimg5maskcontent{
            margin-left: 50%;
            transform: translateX(-140px);
            width: 280px;
            height: 54px;
            font-size: 20px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
          }
        }
      }
      //
      .yyfaimg6:hover .yyfaimg6mask{
          display: block;
        }
.yyfaimg6{
  margin-top: 7px;
        width: 386px;
        height: 520px;

        position: relative;
        .img{
          position: absolute;
          left: 0;
          top: 0;
        }

        .yyfaimg6title{
          color: #fff;
          background: #332716;
          opacity: 0.8;
          text-align: center;
          width: 386px;
          line-height: 60px;
          position: absolute;
          bottom: 0;
        }
        .yyfaimg6mask{
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #FFA000;
          opacity: 0.9;
        }
      }
       .yyfaimg7:hover .yyfaimg7mask{
          display: block;
        }
.yyfaimg7{
  margin-top: 19px;
        width: 386px;
        height: 240px;

        position: relative;
        .img{
          position: absolute;
          left: 0;
          top: 0;
        }

        .yyfaimg7title{
          color: #fff;
          background: #332716;
          opacity: 0.8;
          text-align: center;
          width: 386px;
          line-height: 60px;
          position: absolute;
          bottom: 0;
        }
        .yyfaimg7mask{
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #FFA000;
          opacity: 0.9;
        }
      }
      //
       .yyfaimg8:hover .yyfaimg8mask{
          display: block;
        }
.yyfaimg8{
  margin-top: 16px;
        width: 386px;
        height: 302px;

        position: relative;
        .img{
          position: absolute;
          left: 0;
          top: 0;
        }

        .yyfaimg8title{
          color: #fff;
          background: #332716;
          opacity: 0.8;
          text-align: center;
          width: 386px;
          line-height: 60px;
          position: absolute;
          bottom: 0;
        }
        .yyfaimg8mask{
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #FFA000;
          opacity: 0.9;
        }
      }
.yyfa{
  margin: 0 auto;
  // width: 1903px;
  height: 1153px;
  background: #fff;
  .yyfacontent{
    width: 1200px;
    height: inherit;
    margin: 0 auto;

    .yyfaimg{
       display: flex;
    flex-flow: column wrap;
      width: 1200px;
      height: 850px;

      margin: 0 auto;

    }
  }
}
.yyfatitle{
  width: 500px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 77px;
  margin-bottom: 88px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('.././assets/righticon.png') no-repeat center center;
  }
}
//解决方案
.jjfalist{
  width: 1200px;
  height: 80px;

 margin: 0 auto;
  .jjfalistevery{
    cursor: pointer;
    text-align: center;
    width: 20%;
    float: left;
    height: inherit;
    span{
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      display: inline-block;
      line-height: 80px;
    }
    span:hover{
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #FFA000;
      display: inline-block;
      line-height: 80px;
      border-bottom: 2px solid #FFA000;
    }
  }
}
.indeximg{
  // width: 1903px;
  height: 800px;
  margin: 0 auto;
  background: url('.././assets/homebackground.png') no-repeat center center;
  background-size: 1918px 800px;
}
.indexlogo{
  float: left;
  width: 220px;
  height: 52px;
  margin-left: 360px;
  margin-top: 32px;
  background: url('.././assets/logo.png') no-repeat center center;
}

.indexlist{

  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  float: left;
  margin-left: 282px;
  margin-top: 41px;
  .indexlistul{
    display: flex;

    li{
      cursor: pointer;
      text-align: center;
      width: 160px;
      line-height: 45px;
      span:hover{
        cursor: pointer;
        text-align: center;
        color: #FFA000;
      }
    }

  }
  // .indexlistevery{
  //   cursor: pointer;
  //   text-align: center;
  //   float: left;
  //   width: 160px;
  //   line-height: 30px;
  //   span{
  //     display: inline-block;
  //   }
  //   span:hover{
  //     color: #FFA000;
  //     border-bottom: 2px solid #FFA000;
  //   }
  // }
}
.hxys{
  // width: 1903px;
  height: 834px;

  margin: 0 auto;
}
.hxyscontent{
  width: 1200px;
  height: inherit;
  margin: 0 auto;

}
.hxystitle{
  width: 450px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 85px;
  margin-bottom: 65px;
  display: flex;
  justify-content: space-between;
  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('.././assets/righticon.png') no-repeat center center;
  }
}
.hxysbottomevery{
  margin-bottom: 86px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .hxysbottomeveryright{
    width: 962px;
    p{
      line-height: 53px;
      font-size: 24px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #282A2E;
    }
    div{
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #848079;
    }
  }
  }
.jjfa{
    // width: 1903px;
    // padding-top: 20px;
    // height: 812px;
    margin: 0 auto;
    position: relative;
    // background: url('.././assets/cpjjfabackground.png') no-repeat center;
    // background-size: 1903px 812px;
  }
  .jjfacontent{
    width: 1200px;
    // height: 747px;
    margin: 0 auto;

  }
 .jjfatitle1{
  width: 746px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 77px;
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;

  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('.././assets/righticon.png') no-repeat center center;
  }
}
.jjfatitle2{
  width: 557px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 77px;
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;

  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('.././assets/righticon.png') no-repeat center center;
  }
}
.jjfatitle3{
  width: 515px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 77px;
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;

  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('.././assets/righticon.png') no-repeat center center;
  }
}
.jjfatitle4{
  width: 519px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 77px;
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;

  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('.././assets/righticon.png') no-repeat center center;
  }
}
.jjfatitle5{
  width: 587px;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 77px;
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;

  .hxystitleback{
    width: 108px;
    height: 30px;
    background: url('.././assets/lefticon.png') no-repeat center center;
  }
  .hxystitl-ti{
    font-size: 38px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #282A2E;
  }
  .hxystitleback2{
    width: 108px;
    height: 30px;
    background: url('.././assets/righticon.png') no-repeat center center;
  }
}
// 轮播
.jjfalisteveryindurce{
  font-size: 18px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #282A2E;
  text-indent: 2rem;
}
//
  .box {
    margin-top: 30px;
            width: 1200px;
            // height: 600px;

            // overflow: hidden;
            cursor: pointer;
        }

        .box ul,
        .box ol {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        .box ul li {
            width: 1200px;
            // height: 600px;
            display: none;
        }

        .box ul li  {
          .homebanner1{
            margin-top: 20px;
            // margin-left: 50%;
            // transform: translate(-600px);
            width: 1200px;
            height: 970px;
            margin-bottom: 88px;
          }
          .homebanner2{
            margin-top: 20px;
            // margin-left: 50%;
            // transform: translate(-600px);
            width: 1200px;
            height: 522px;
            margin-bottom: 88px;
          }
          .homebanner3{
            margin-top: 20px;
            // margin-left: 50%;
            // transform: translate(-600px);
            width: 1200px;
            height: 988px;
            margin-bottom: 88px;
          }
          .homebanner4{
            margin-top: 30px;
            // margin-left: 50%;
            // transform: translate(-600px);
            width: 1200px;
            height: 856px;
            margin-bottom: 88px;
          }
          .homebanner5{
            margin-top: 30px;
            // margin-left: 50%;
            // transform: translate(-600px);
            width: 1200px;
            height: 1076px;
            margin-bottom: 88px;
          }

        }

        .box ol {
            width: 120px;
            position: absolute;
            left: 50%;
            margin-left: -40px;
            bottom: 20px;
            height: 20px;
            background: #fff;
            display: flex;
            justify-content: space-evenly;
            padding: 10px 0;
            border-radius: 20px;
        }

        .box ol li {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #f00;
            border: 1px solid #999;
        }

        .box ol li.current {
            background: #00f;
        }

        .box ul li.current {
            display: block;
        }

        .box .left{
          position: absolute;
            top: 50%;
            left: 100px;
            width: 60px;
            height: 60px;
            margin-top: -15px;
            text-align: center;
            line-height: 30px;
            text-decoration: none;
            font-weight: bold;
        }
        .box .right {
           position: absolute;
            top: 50%;
            right: 100px;
            width: 60px;
            height: 60px;
            margin-top: -15px;
            text-align: center;
            line-height: 30px;
            text-decoration: none;
            font-weight: bold;
        }

        .left {
            left: 0;
        }

        .right {
            right: 0;
        }
        .jjfalistevery_span{
        color: #FFA000 !important;
         border-bottom:2px solid #FFA000 !important;
}
</style>
